<template>
  <div class="product-container">
    <!-- QbNavbar -->
    <qb-navbar title="泉邦产品"></qb-navbar>
    <!-- title -->
    <qb-title title="泉邦产品" subtitle="Quanbang Group Product"></qb-title>
    <!-- think -->
    <div class="think">
      <van-image
        width="100%"
        height="100%"
        lazy-load
        :src="require('@/assets/images/product/product-think.png')"
        alt=""
      />
      <div class="btn" @click="$router.push('/product/think')"></div>
    </div>
    <div class="empower">
      <van-image
        width="100%"
        height="100%"
        lazy-load
        :src="require('@/assets/images/product/product-empower.png')"
        alt=""
      />
      <div class="btn" @click="$router.push('/product/empower')"></div>
    </div>
    <div class="extension">
      <van-image
        width="100%"
        height="100%"
        lazy-load
        :src="require('@/assets/images/product/product-extension.png')"
        alt=""
      />
      <div class="btn" @click="$router.push('/product/extension')"></div>
    </div>
    <!-- product-brand-advantage  -->
    <ProductBrandAdvantage></ProductBrandAdvantage>
  </div>
</template>

<script>
import ProductBrandAdvantage from './components/product-brand-advantage.vue'
export default {
  name: 'Product',
  components: {
    ProductBrandAdvantage
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
img {
  display: block;
  height: 100%;
  width: 100%;
}

.product-container {
  .btn {
    position: absolute;
    width: 145px;
    height: 37px;
  }
  ::v-deep .van-image {
    display: block;
  }
  .think {
    padding-top: 24px;
    position: relative;
    .btn {
      background: url(~@/assets/images/product/btn-1.png);
      background-size: cover;
      top: 251px;
      left: 32px;
    }
  }
  .empower {
    position: relative;
    .btn {
      background: url(~@/assets/images/product/btn-2.png);
      background-size: cover;
      top: 694px;
      left: 152.9px;
    }
  }
  .extension {
    position: relative;
    margin-bottom: 120px;
    .btn {
      background: url(~@/assets/images/product/btn-1.png);
      background-size: cover;
      top: 550px;
      left: 188px;
    }
  }
}
</style>
