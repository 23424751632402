<template>
  <div class="advantage-container">
    <h3 class="title">品牌优势</h3>
    <h3 class="subtitle">Brand advantage</h3>
    <ul class="content">
      <li>
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/course/advantage-1.png')"
          alt=""
        />
      </li>
      <li>
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/course/advantage-2.png')"
          alt=""
        />
      </li>
      <li>
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/course/advantage-3.png')"
          alt=""
        />
      </li>
      <li>
        <van-image
          width="100%"
          height="100%"
          lazy-load
          :src="require('@/assets/images/course/advantage-4.png')"
          alt=""
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BrandAdvantage'
}
</script>

<style lang="less" scoped>
.advantage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 89px;
  .title {
    font-size: 21px;
    font-weight: 700;
    line-height: 29px;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #a0a0a1;
    line-height: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  li {
    width: 142px;
    height: 164px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-top: 30px;
    margin-bottom: 37px;
  }
}
</style>
